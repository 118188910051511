import { useState, useEffect } from 'react'
import { registerParameterization as createParameterization, updateParameterization as updateParameterizationService } from '../../services/Parameterizations';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { reloadData, resetReload } from '../../store/features/parameterization/parameterizationSlice';

export const useFormParameterization = () => {

    const [isRegister, setIsRegister] = useState(false);
    const [messageRegister, setMessageRegister] = useState('')
    const dispatch = useDispatch();
    const [keyReconstructFragment, setKeyReconstructFragment] = useState(1)
    const { parameterizationUpdate, isUpdate } = useSelector((state: any) => state.parameterization)
    const [initialState, setInitialState] = useState({
        name: '',
        link: '',
    })


    const registerParameterization = async (parameterization: any, reset: any) => {
        try {
            await createParameterization(parameterization);
            Swal.fire({
                icon: 'success',
                title: 'Parámetro creado con exito',
                showConfirmButton: false,
                timer: 1500
            })

            dispatch(reloadData(parameterization.code))
            const button = document.getElementById('close-modal');
            button?.click();
            // reset form
            reset();
            setIsRegister(false)
            dispatch(resetReload())

        } catch (err: any) {
            setMessageRegister(err.response.data.message)
            setIsRegister(true)
        }
    }


    const updateParameterization = async (parameterization: any, reset: any) => {
        try {

            await updateParameterizationService({ id: parameterizationUpdate._id, ...parameterization });
            Swal.fire({
                icon: 'success',
                title: 'Parámetro actualizado con exito',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(reloadData(parameterization.cedula))
            const button = document.getElementById('close-modal');
            button?.click();
            // reset form
            reset();
            setIsRegister(false)
            dispatch(resetReload())

        } catch (err: any) {
            setMessageRegister(err.response.data.message)
            setIsRegister(true)
        }
    }


    const resetFormInital = () => {
        setInitialState({
            name: '',
            link: '',
        })
    }

    useEffect(() => {
        setInitialState(parameterizationUpdate)
        setKeyReconstructFragment(0)
    }, [isUpdate, parameterizationUpdate])


    useEffect(() => {

        return () => {
            resetFormInital();
            setKeyReconstructFragment(1)
        }
    }, [])




    return {
        isRegister,
        messageRegister,
        registerParameterization,
        isUpdate,
        updateParameterization,
        initialState,
        keyReconstructFragment
    }
}
