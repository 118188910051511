import { useDispatch, useSelector } from "react-redux"
import { nextStep, previusStep, setPage } from "../../store/features/paginator/paginatorSlice";

export const Paginator = ({className = ''}: {className?: string}) => {
    // toda la logica para un custom hook
    const { totalPages, page } = useSelector((state: any) => state.paginator)

    const dispath = useDispatch();

    const generatorItems = () => {
        const list: any = []
        for (let index = 1; index <= totalPages; index++) {

            list.push(<li key={index} className={`page-item ${index === page ? 'active' : ''}`}><button className="page-link custom-a" onClick={() => dispath(setPage(index))}>{index}</button></li>)
        }
        return list
    }


    return (
        <nav className={className}>
            <ul className="pagination justify-content-end">
                <li className={`page-item ${page <= 1? 'disabled': ''}`} >
                    <button className="page-link custom-a" onClick={()=>dispath(previusStep())}>Previous</button>
                </li>
                {generatorItems()}

                <li className={`page-item ${page >= totalPages? 'disabled': ''}`}>
                    <button className="page-link custom-a" onClick={()=>dispath(nextStep())} >Next</button>
                </li>
            </ul>
        </nav>
    )
}
