import '../styles/loginStyle.css';
import { useEffect } from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

const request = {
    scopes: ["User.Read"]
}

export const Login = () => {
    const { login, error } = useMsalAuthentication(InteractionType.Silent, request);
    useEffect(() => {
        if (error) {
            login(InteractionType.Redirect, request);
        }
    }, [error, login]);



    return (
        <>
        </>
    );
}
