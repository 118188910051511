import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AxiosInterceptor } from "./app/interceptors/axios.interceptor";
import { Provider } from 'react-redux';
import { store } from './app/store/app/store';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './app/config/msalConfig';

// fonts
import './fonts/font/neskt.ttf';
import './fonts/font/neskt.woff2';
import './fonts/font/neskt.woff';
import './fonts/font/SerebryakovSemiBold.ttf';
import './fonts/font/SerebryakovSemiBold.otf';
import './fonts/font/SerebryakovSemiBold.woff';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
AxiosInterceptor();

const pca = new PublicClientApplication(msalConfig);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
