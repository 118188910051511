import { useHeader } from "../hooks/Header/useHeader"

export const Header = () => {

    // crear hook para manejar los datos del header 
    const { logout, nameUser } = useHeader()

    return (
        <div className='header'>
            <div className="container">
                <div className="row">
                    <div className="col-11 col-sm-8 col-md-10" >
                        <h3 >finaktiva. - visor de dashboard</h3>
                    </div>
                    <div className="col-1 col-sm-2 col-md-2">
                        <div className="btn-group container-user-option">
                            <button type="button" className="btn dropdown-toggle btn-user-option" data-bs-toggle="dropdown" aria-expanded="false">
                                {nameUser}
                            </button>
                            <ul className="dropdown-menu">
                                <li><button className="dropdown-item" >Perfil</button></li>

                                <li><button className="dropdown-item" >opciones</button></li>
                                <li><hr className="dropdown-divider" /></li>
                                <li><button className="dropdown-item" onClick={logout}>Salir</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



        </div >
    )
}
