import * as Yup from "yup";

const createParameterizationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Minimo 2 caracteres")
    .max(30, "Maximo 30 caracteres!")
    .required("El nombre es requerido"),
  link: Yup.string()
    .url("El link debe ser una url valida")
    .required("El link es requerido"),
});

export default createParameterizationSchema;
