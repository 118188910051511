import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IPaginaroState {
    page: number;
    size: number;
    filter: string;
    totalPages: number;
}

const initialState: IPaginaroState = {
    page: 1,
    size: 10,
    filter: '',
    totalPages: 1,
}

export const paginatorSlice = createSlice({
    name: 'Visualization',
    initialState,
    reducers: {
        nextStep: (state) => {
            state.page += 1
        },
        previusStep: (state) => {
            state.page -= 1
        },
        setTotalPages: (state, total: any) => {
            state.totalPages = total.payload
        },
        setPage: (state, pages: PayloadAction<number>) =>{
            state.page = pages.payload
        },
        resetPaginator: (state) => {
            state.filter = ''
            state.page = 1
            state.size = 10
            state.totalPages = 1
        }

    },
})


export const { nextStep, previusStep, setTotalPages, resetPaginator, setPage } = paginatorSlice.actions
export default paginatorSlice.reducer