import { createSlice } from '@reduxjs/toolkit';

export interface ImodalState {
    contructModal: boolean;
}


const initialState: ImodalState = {
    contructModal: false,
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        destroyModal: (state) => {
            state.contructModal = false
        },
        construcModal: (state) => {
            state.contructModal = true
        },
    },
})


export const {destroyModal, construcModal } = modalSlice.actions
export default modalSlice.reducer