import { LazyExoticComponent } from "react";
import { Parameterization, Login } from "../pages";
import { Visualization } from "../pages/Visualization";


type JSXComponent = () => JSX.Element;

export interface IRoute {
  to: string;
  path: string;
  Component: LazyExoticComponent<JSXComponent> | JSXComponent;
  name: string;
  rol: string[];
}

export const routes: IRoute[] = [

  {
    to: "parameterization-page",
    path: "parameterization-page",
    Component: Parameterization,
    name: "Parametrización",
    rol: ['ADMIN']
  },
  {
    to: "visualization-page",
    path: "visualization-page",
    Component: Visualization,
    name: "Visualización",
    rol: ['ADMIN', 'USER']
  },
];

export const routesRegister: IRoute[] = [
  {
    to: "login",
    path: "login",
    Component: Login,
    name: "Login",
    rol: []
  },
];


export const createRoutesByRol = (rol: string):IRoute[] => {
    return routes.filter((item) => item.rol.includes(rol));
  };
  