import { useDispatch } from "react-redux"

import { destroyModal as destroyVisualization } from '../../store/features/visualization/visualizationSlice'
import { destroyModal as destroyParameterization } from '../../store/features/parameterization/parameterizationSlice'

export const ModalForm = ({ origin, formItems, title }: { origin: string, formItems: JSX.Element, title: string }) => {

    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(destroyParameterization());
        dispatch(destroyVisualization());
    }

    return (
        <div className="modal-custom" id={origin}>
            <div className="modal-dialog">
                <div className="modal-content dialog-custom" style={{ color: 'black' }}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button onClick={() => closeModal()} id="close-modal" className='btn btn-secondary btn-icon'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                        </svg></button>
                    </div>
                    {formItems}
                </div>
            </div>
        </div>
    )
}

