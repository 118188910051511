import { useEffect, useState } from "react";
import { validateUser } from "../../services/auth";
import { useHeader } from "../Header/useHeader";

export const useLogin = () => {
  const [rol, setRol] = useState("USER");

  const { nameUserCompleted } = useHeader();

  const validateRolUser = async (email: string) => {
    try {
      const { data } = await validateUser({ email });
      setRol(data.data.rol[0]);
    } catch (err) {
      setRol("USER");
    }
  };

  useEffect(() => {
    validateRolUser(nameUserCompleted);
  }, [nameUserCompleted]);

  return {
    rol,
  };
};
