import { configureStore } from "@reduxjs/toolkit";
import visualizationReducer  from "../features/visualization/visualizationSlice";
import paginatorReducer from "../features/paginator/paginatorSlice"
import ParameterizationReducer from '../features/parameterization/parameterizationSlice'
import modalReducer from '../features/modal/modalSlice'

export const store = configureStore({
    reducer: {
        visualization: visualizationReducer,
        paginator: paginatorReducer,
        parameterization: ParameterizationReducer,
        modal: modalReducer
    }
})
