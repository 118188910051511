import React from 'react'
import { Formik } from "formik";
import { useFormParameterization } from '../../hooks/Parameterization/useFormParameterization';
import createParameterizationSchema from '../../validationsFoms/createParameterization';


export const FormParameterization = () => {
    const { isRegister, messageRegister, registerParameterization, isUpdate, initialState, updateParameterization, keyReconstructFragment } = useFormParameterization();
    return (
        <React.Fragment key={keyReconstructFragment}>
            <div className="card-body">
                <Formik
                    initialValues={initialState}
                    validationSchema={createParameterizationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(false);
                        const {_id, ...res}:any = values

                        if (isUpdate) {
                            await updateParameterization(res, resetForm)
                        } else {
                            await registerParameterization(res, resetForm)
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Name</label>
                                <input
                                    className={`form-control ${errors.name && touched.name && errors.name ? 'is-invalid' : ''}`}
                                    id="name"
                                    type="name"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}
                                />
                                <div className={errors.name && touched.name && errors.name ? `invalid-feedback` : `valid-feedback`}>
                                    {errors.name && touched.name && errors.name}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Link</label>
                                <input
                                    className={`form-control ${errors.link && touched.link && errors.link ? 'is-invalid' : ''}`}
                                    id="link"
                                    type="text"
                                    name="link"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.link}
                                />
                                <div className={errors.link && touched.link && errors.link ? `invalid-feedback` : `valid-feedback`}>
                                    {errors.link && touched.link && errors.link}
                                </div>
                            </div>
                            <div className="d-grid">
                                {isRegister &&
                                    <div className='pt-2 pb-3 alert alert-warning text-center' style={{ fontSize: 14, textAlign: 'justify' }}>{messageRegister}</div>
                                }
                                <button className="btn text-light main-bg" type="submit" disabled={isSubmitting}>{isUpdate ? 'Actualizar' : 'Crear'}</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}
