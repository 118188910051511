import { createSlice } from '@reduxjs/toolkit';

export interface IParameterizationUpdate {
    _id: number;
    name: string;
    link: string;
}
export interface IParameterizationState {
    reloadDate: boolean;
    code: string;
    contructModal: boolean;
    parameterizationUpdate: IParameterizationUpdate;
    isUpdate: boolean;
}


const initialState: IParameterizationState = {
    reloadDate: false,
    code: '',
    contructModal: false,
    parameterizationUpdate: {
        _id: 0,
        name: '',
        link: ''
    },
    isUpdate: false
}

export const ParameterizationSlice = createSlice({
    name: 'Parameterization',
    initialState,
    reducers: {
        reloadData: (state, code: any) => {
            state.reloadDate = true
            state.code = code.payload
        },
        resetReload: (state) => {
            state.reloadDate = false
            state.code = ''
        },
        destroyModal: (state) => {
            state.contructModal = false
            state.isUpdate = false
            state.parameterizationUpdate =  {
                _id: 0,
                name: '',
                link: ''
            }
        },
        construcModal: (state) => {
            state.contructModal = true
        },
        setDataUpdate: (state, dataUpdate: any) =>{
            state.parameterizationUpdate = dataUpdate.payload
            state.isUpdate = true
        }
    },
})


export const { reloadData, resetReload, destroyModal, construcModal, setDataUpdate } = ParameterizationSlice.actions
export default ParameterizationSlice.reducer