import { ModalForm } from "../components/ModalForm/ModalForm"
import { Table } from "../components/Table/Table"
import { FormParameterization } from "../components/Parameterization/FormParameterization"
import { useParameterization } from "../hooks/Parameterization/useParameterization"

export const Parameterization = () => {

    const { createParameterization, updateParameterization, deleteParameterization, data, openModal, isUpdate } = useParameterization()

    return (
        <>
            {openModal && <ModalForm origin='Visualization' title={`${isUpdate ? 'Actualizar Parámetro' : 'Crear Parámetro'}`} formItems={<FormParameterization />} />}
            <div className="container pt-5 parameterization-container">
                <div className="row">
                    <div className="col-9 col-sm-8 col-md-8 col-lg-9 col-xl-9">
                        <button type="button" className="btn btn-primary" onClick={createParameterization}>Crear Parámetro</button>
                    </div>
                    <div className="col-3 col-sm-2 col-md-4 col-lg-3 col-xl-3 pb-4">
                        <h1>Parametrización</h1>
                    </div>
                </div>
                <Table list={data} actions={[
                    { name: 'Update', action: updateParameterization },
                    { name: 'Delete', action: deleteParameterization },
                ]} />
            </div>
        </>
    )
}
