import { useState, useEffect } from "react";
import { IObjectAction } from "../../interface/interfaces";

interface IUseTable {
  list: Array<any>;
  actions?: Array<IObjectAction>;
}
/** HOOOK PARA MANEJO DE COMPONENTE TABLA */
/** TODO: manejo de servicio externo para editar permitir que
 * el usuario envie la cantidad de accriones requeridas junto con sus
 * funciones ejemplo eliminar y editar con confirmacion y desencadenador
 */

export const useTable = ({ list, actions }: IUseTable) => {
  // procesar la data para devolver una lista de registros
  const [data, setData] = useState<Array<any>>([]);
  const [headers, setHeaders] = useState<Array<any>>([]);

  const procesData = (dataSet: any, acti: any) => {
    // Recorrer la data y generar los registros para la tabla
    let headersList = Object.keys(dataSet[0] || []);
    // elimianr el id de los headers
    headersList = headersList.filter((item) => item !== "id" && item !== "_id");
    // validar si vienen actions para retornar un action
    if (acti) {
      // setear en los headers el name del action
      acti.forEach(({ name }: IObjectAction) => {
        headersList.push(name || "");
      });
    }
    setHeaders(headersList);
    setData(dataSet);
  };

  const returnDataProcesForItem = (item: any, index: number) => {
    return (
      <tr key={index}>
        {headers.map((hea) =>
          hea !== "Update" && hea !== "Delete" && hea !== "Suscribe" ? (
            <td key={hea}>
              {item[hea].length >= 22
                ? item[hea].substring(0, 30) + "..."
                : item[hea]}
            </td>
          ) : hea === "Update" ? (
            <td key={hea}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={
                  actions
                    ? () => {
                        actions[0].action(item);
                      }
                    : () => {}
                }
              >
                Actualizar
              </button>
            </td>
          ) : hea === "Suscribe" ? (
            <td key={hea}>
              <button
                type="button"
                className="btn btn-warning"
                onClick={
                  actions
                    ? () => {
                        actions[0].action(item);
                      }
                    : () => {}
                }
              >
                Suscribe
              </button>
            </td>
          ) : (
            <td key={hea}>
              <button
                className="btn btn-secondary btn-icon"
                onClick={
                  actions
                    ? () => {
                        actions[actions.length <= 1 ? 0 : 1].action(item);
                      }
                    : () => {}
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>
              </button>
            </td>
          )
        )}
      </tr>
    );
  };

  useEffect(() => {
    procesData(list, actions);
  }, [list, actions]);

  return {
    data,
    headers,
    returnDataProcesForItem,
  };
};
