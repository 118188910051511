import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

export const useHeader = () => {
  const { accounts, instance } = useMsal();
  const [nameUser, setNameUser] = useState("Duvan");
  const [nameUserCompleted, setNameUserCompleted] = useState("");

  const logout = () => {
    localStorage.clear();
    instance.logout({
      postLogoutRedirectUri: "/",
    });
  };

  useEffect(() => {
    let name = accounts[0]?.username;
    setNameUserCompleted(accounts[0]?.username);
    name = name.length >= 22 ? name.substring(0, 10) + "..." : name;
    setNameUser(name);
  }, [accounts]);

  return {
    logout,
    nameUser,
    nameUserCompleted,
  };
};
