import  { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getParameterization , deleteParameterization as deleteParameterizationService } from '../../services/Parameterizations';
import { setTotalPages } from '../../store/features/paginator/paginatorSlice';
import { construcModal, reloadData, setDataUpdate } from '../../store/features/parameterization/parameterizationSlice';
import Swal from 'sweetalert2';

export const useParameterization = () => {
    const [data, setData] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const dataParameterization = useSelector((state: any) => state.parameterization);
    const isOpne = useSelector((state: any) => state.parameterization.contructModal);
    const { page, size, filter } = useSelector((state: any) => state.paginator)
    const dispath = useDispatch();

    const getListParameterizations = async (page: number, size: number, filter: string, dispatch: any) => {
        try {
            const { data }: any = await getParameterization(page, size, filter);    
            dispatch(setTotalPages(data.totalPages));
            setData(data.data);
        } catch (err: any) {    
            setData([])
        }
    }

    const updateParameterization = async (event: any) => {
        dispath(setDataUpdate(event))
        dispath(construcModal())
    }

    
    const deleteParameterization = async (event: any) => {
        try {
            await deleteParameterizationService(event._id)
            Swal.fire({
                icon: 'success',
                title: 'Parámetro eliminado con exito',
                showConfirmButton: false,
                timer: 1700
            })
            dispath(reloadData(event.cedula))
        }catch(err: any){
            Swal.fire({
                icon: 'error',
                title: err.message,
                showConfirmButton: false,
                timer: 1700
            })
        }
    }

    
    const createParameterization = async () => {
        setOpenModal(true)
        dispath(construcModal());
    }

    useEffect(() => {
        setOpenModal(isOpne)
    }, [isOpne])


    useEffect(() => {
        getListParameterizations(page, size, filter, dispath)
    }, [dispath, dataParameterization, page, filter, size])



    return {
        createParameterization,
        updateParameterization,
        deleteParameterization,
        data,
        openModal,
        isUpdate: dataParameterization.isUpdate
    }
}
