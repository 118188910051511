import { BrowserRouter } from 'react-router-dom'
import { Routes, Route, Navigate } from 'react-router-dom'
import { IRoute, routesRegister } from './routes'

import '../styles/loginStyle.css'

export const RegisterNavigate = () => {

    return (
        <div className='background-register'>
            <BrowserRouter>
                <Routes>
                    {routesRegister.map(({ path, to, Component }: IRoute) => (
                        <Route key={to} path={path} element={<Component />}></Route>
                    ))}

                    <Route path='/*' element={<Navigate to={routesRegister[0].to} replace />}></Route>
                </Routes>
            </BrowserRouter>

        </div>
    )
}
