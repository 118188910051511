import { useMsal } from "@azure/msal-react";
import { Navigation } from "./app/routes/Navigation";
import { RegisterNavigate } from "./app/routes/RegisterNavigate";

function App() {
  const { accounts } = useMsal();

  return accounts.length > 0 ? (
    <>
      <Navigation />
    </>
  ) : (
    <RegisterNavigate />
  );
}

export default App;
