import axios from 'axios';
import {listEndPointParameterization} from '../constants/endpoints'

export const getParameterization = (page: number, size: number, filter: string) => {
    return axios.get(`${listEndPointParameterization.list}?page=${page}&size=${size}`);
}

export const registerParameterization = (data: any) => {
    return axios.post(listEndPointParameterization.create, data)
} 

export const updateParameterization = (data: any) => {
    return axios.put(listEndPointParameterization.update, data)
}

export const deleteParameterization = (id: any) => {
    return axios.delete(`${listEndPointParameterization.delete}?id=${id}`)
}