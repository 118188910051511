import { config } from "../config/config";

// auth
const urlAuth = config.apiAuthBack;
// Parameterizations
const urlParameterizations = config.apiParamBack;

const listEndpointAuth = {
  validateUser: urlAuth,
};

const listEndPointParameterization = {
  list: urlParameterizations,
  create: urlParameterizations,
  update: urlParameterizations,
  delete: urlParameterizations,
};

export { listEndpointAuth, listEndPointParameterization };
