import logo from '../../logo-finaktiva-responsive.svg'
import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Routes, Route, NavLink, Navigate } from 'react-router-dom'
import { IRoute, createRoutesByRol, routes } from './routes'
import { Header } from '../layout/Header'
import { useLogin } from '../hooks/Auth/useLogin'
// import { useSelector } from 'react-redux'
export const Navigation = () => {

    const {rol} = useLogin()

    return (
        <Suspense fallback={<span>..loanding</span>}>
            <BrowserRouter>
                <div className='main-layout'>
                    <Header />
                    <div className='nav'>
                        <nav>
                            <img src={logo} style={{ width: 150, marginTop: 110, marginBottom: 110 }} alt='React-logo'></img>
                            <ul>
                                {createRoutesByRol(rol).map(({ to, name }: IRoute) => (
                                    <li key={to}>
                                        <NavLink to={to} className={({ isActive }) => isActive ? 'nav-active' : ''}>{name}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className='content'>
                        <Routes>
                            {routes.map(({ path, to, Component }: IRoute) => (
                                <Route key={to} path={path} element={<Component />}></Route>
                            ))}

                            <Route path='/*' element={<Navigate to={routes[1].to} replace />}></Route>
                        </Routes>
                    </div>

                    <div className='footer'>
                        {/* aqui va mi footer */}
                    </div>

                </div>

            </BrowserRouter>
        </Suspense>
    )
}
