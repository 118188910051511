import { Paginator } from "../components/Pagination/Paginator";
import { useParameterization } from "../hooks/Parameterization/useParameterization";
import { useState } from "react";

export const Visualization = () => {

  const { data } = useParameterization()

  const [selectedValue, setselectedValue] = useState("https://finaktiva.com/")

  // Handle changes in the select element
  const handleSelectChange = (event: any) => {
    setselectedValue(event.target.value);
  };

  return (
    <>
      <div className="container pt-5">
        <div className="row">
          <div className="col-9">
            <select value={selectedValue} onChange={handleSelectChange} className="form-select form-select-lg mb-3" aria-label="Large select example">
              <option value="https://finaktiva.com/">Select an option</option>
              {data.map((item: any, index: number) => (
                <option key={index} value={item.link}>{item.name}</option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <Paginator />
          </div>
          <div className="col-12">
            <div className="ratio ratio-16x9 vision-container-iframe">
              <iframe src={selectedValue} title="Finaktiva"></iframe>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
