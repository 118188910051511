import { useTable } from "../../hooks/components/useTable";
import { IObjectAction } from "../../interface/interfaces";
import { Paginator } from "../Pagination/Paginator";

interface ITable {
  list: Array<any>;
  actions?: Array<IObjectAction>;
  removePaginator?: boolean;
}

export const Table = ({ list, actions, removePaginator = true }: ITable) => {
  // crear hook para el manejo de los datos
  // retornar la tabla construida y permitir personalizacion externa
  const { data, headers, returnDataProcesForItem } = useTable({
    list,
    actions,
  });
  return (
    <>
      <div className="table-responsive">
        <table className="table table-dark table-striped">
          <thead>
            <tr>
              {headers.map((item) => (
                <th key={item} scope="col">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => returnDataProcesForItem(item, index))}
          </tbody>
        </table>
      </div>
      {removePaginator && <Paginator />}
    </>
  );
};
