import { createSlice } from '@reduxjs/toolkit';

export interface IVisualizationUpdat {
    id: number;
    apellido: string;
    cedula: string;
    edad: string;
    genero: string;
    nombres: string;
    segundo_apellido: string;
}
export interface IVisualizationState {
    reloadDate: boolean;
    cedula: string;
    contructModal: boolean;
    VisualizationUpdate: IVisualizationUpdat;
    isUpdate: boolean;
}


const initialState: IVisualizationState = {
    reloadDate: false,
    cedula: '',
    contructModal: false,
    VisualizationUpdate: {
        id: 0,
        apellido: '',
        cedula: '',
        edad: '',
        genero: '',
        nombres: '',
        segundo_apellido: ''
    },
    isUpdate: false
}

export const VisualizationSlice = createSlice({
    name: 'Visualization',
    initialState,
    reducers: {
        reloadData: (state, cedula: any) => {
            state.reloadDate = true
            state.cedula = cedula.payload
        },
        resetReload: (state) => {
            state.reloadDate = false
            state.cedula = ''
        },
        destroyModal: (state) => {
            state.contructModal = false
            state.isUpdate = false
            state.VisualizationUpdate =  {
                id: 0,
                apellido: '',
                cedula: '',
                edad: '',
                genero: '',
                nombres: '',
                segundo_apellido: ''
            }
        },
        construcModal: (state) => {
            state.contructModal = true
        },
        setDataUpdate: (state, dataUpdate: any) =>{
            state.VisualizationUpdate = dataUpdate.payload
            state.isUpdate = true
        }
    },
})


export const { reloadData, resetReload, destroyModal, construcModal, setDataUpdate } = VisualizationSlice.actions
export default VisualizationSlice.reducer